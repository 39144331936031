<template>
  <b-card-code>

    <b-row>
      <b-col md="6">
        <h4>{{ item.ROL }} - {{ item.GRUPO }}</h4>
      </b-col>
      <b-col md="6">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Buscar</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Buscar"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>

    </b-row>
    <vue-good-table
      v-if="item.accesos && item.accesos.length"
      :columns="columns"
      :is-loading="loading"
      :rows="item.accesos"
      :fixed-header="true"
      :max-height="screenHeight"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'module'"
          class="text-nowrap"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>



        <!-- Column: Common -->
        <span v-else>
          <b-form-checkbox
            v-model="item.accesos[props.row.originalIndex][props.column.field]"
            :checked="props.formattedRow[props.column.field]"
          />
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-row class="mt-4">
      <!-- submit and reset -->
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          :disabled="loading"
          @click="save(item)"
        >
          <span v-if="!loading">Guardar</span>
          <span v-if="loading">Un momento...</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          variant="outline-secondary"
          to="roles-list"
        >
          Cancelar
        </b-button>
      </b-col>
    </b-row>

  </b-card-code>

</template>

<script>
import {
  BFormSelect, BPagination, BFormCheckbox, BCol, BFormGroup, BFormInput, BRow, BButton,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {VueGoodTable} from "vue-good-table"
import environment from "@/environment"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BFormInput, BFormGroup, BCol, BRow,
    BPagination, BFormSelect, BFormCheckbox,
    BCardCode,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      item: {},
      columns: [
        {
          label: 'Módulo',
          field: 'module',
        },
        {
          label: 'Consultar',
          field: 'read',
        },
        {
          label: 'Crear',
          field: 'create',
        },
        {
          label: 'Editar',
          field: 'write',
        },
        {
          label: 'Eliminar',
          field: 'delete',
        },
      ],
      permissionsData: [
        {
          module: "Gerencias Directivas",
          subject: "administrative-management",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Tipos de Centros de Costos",
          subject: "cost-center-type",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Centros de Costos",
          subject: "cost-center",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Tipos de Cuentas Contables",
          subject: "accounting-account-type",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Categorías de Cuentas Contables",
          subject: "accounting-account-category",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Cuentas Contables",
          subject: "accounting-account",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Auxiliar de Cuentas Contables",
          subject: "accounting-account-assistant",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Rangos de Prestamos Hipotecarios",
          subject: "mortgage-loan-range",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Reporte Rentabilidad",
          subject: "reports-rentability",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Rentabilidad de Sucursales - Cálculo de Rentabilidad",
          subject: "branch-financial-rentability-report-dynamic",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Rentabilidad de Sucursales - Reporte de Movimientos",
          subject: "branch-profitability-movements-report",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Rentabilidad de Sucursales - Reporte de Rentabilidad Finanzas",
          subject: "branch-financial-rentability-report",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Rentabilidad de Sucursales - Reporte de Rentabilidad Distribuido",
          subject: "branch-redistribution-report",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Rentabilidad de Sucursales - Reporte Rentabilidad Distribuida Dínamica",
          subject: "branch-redistribution-report-dynamic",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Gestión de Periodos",
          subject: "period-management",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Gestión de Auxiliares",
          subject: "auxiliary-management",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Gestión de Auxiliares - Aprobadores",
          subject: "auxiliary-management-approves",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Aprobación de Auxiliares",
          subject: "auxiliary-approval",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Catálogo de Auxiliares",
          subject: "branch-aux-report",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Roles",
          subject: "user-roles-list",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Usuarios",
          subject: "users-list",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        },
        {
          module: "Homologación Interbancos",
          subject: "inter-bank-homologation",
          "read": false,
          "update": false,
          "create": false,
          "delete": false
        }
      ],
      screenHeight: '300',
      searchTerm: '',
    }
  },
  computed: {
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
  },
  created() {
    this.screenHeight = (screen.height - 470) + 'px'
    this.load()
  },
  methods: {
    async load() {
      try {
        this.loading = true
        const { data } = await this.$http.get(`${environment.uri}/users/role/${this.$route.params.id}`)
        this.item = data
        this.item.accesos = this.permissionsData
        if (this.item.ACCESOS) {
          this.item.accesos = JSON.parse(this.item.ACCESOS)
        }

      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.apiMessage || 'No fue posible procesar su solicitud',
            icon: 'BellIcon',
          },
        })
      } finally {
        this.loading = false
      }
    },
    async save(item) {
      console.log('ITEM: ', item)
      try {
        this.loading = true
        await this.$http.put(`${environment.uri}/users/role/${item.ID_REGISTRO}`, {
          rol: item.ROL,
          grupo: item.GRUPO,
          descripcion: item.DESCRIPCION,
          status: item.STATUS,
          accesos: JSON.stringify(item.accesos)
        })

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Actualizado',
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })

        await this.$router.replace('/cda/admin/roles-list')
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.apiMessage || 'No fue posible procesar su solicitud',
            icon: 'BellIcon',
          },
        })
      } finally {
        this.loading = false
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
